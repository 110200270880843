<template>
  <div>
    <Navbar page="Plugin de URLs" link="/requisicao" nameLink="Gestão de Requisições" />
    <div class="mx-4 my-4 md:mx-8 md:my-8">
     <div class="grid grid-cols-12 gap-x-6 gap-y-2 mb-3">
        <div class="col-span-12 md:col-span-3 2xl:col-span-2">
          <button
            @click="novo"
            type="button"
            class="transition duration-200 bg-yellow-300 hover:bg-yellow-400 focus:bg-yellow-700 focus:shadow-sm focus:ring-4 focus:ring-yellow-500 focus:ring-opacity-50 text-white py-2 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold text-center w-full"
            :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }"
          >
            <span class="inline-block mr-2">Nova configuração</span>
          </button>
        </div>

        <div class="col-span-12 md:col-span-3 2xl:col-span-2">
          <button @click="excluirSelecionadas" type="button" class="transition duration-200 bg-red-500 hover:bg-red-700 text-white py-2 rounded-lg text-sm shadow-sm hover:shadow-md w-full font-semibold text-center mb-2">
            <span class="inline-block">Arquivar selecionadas</span>
          </button>
        </div>

      </div>

      <div class="grid grid-cols-12 gap-6 mb-3">
        
        <div class="col-span-12">
          <label for="arquivadas" class="text-sm mr-2">
          <input @change="start" v-model="arquivadas" type="checkbox" id="arquivadas" class="rounded-sm">
          Listar arquivadas
          </label>
        </div>
      </div>

      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr class="text-center text-xs tracking-wider">
                    <th scope="col" class="px-3 py-3 text-left text-xs font-medium text-gray-500 tracking-wider cursor-pointer">
                      <svg @click="selecionarTodos" xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#000000" viewBox="0 0 256 256"><path d="M220,48V208a12,12,0,0,1-12,12H136a4,4,0,0,1,0-8h72a4,4,0,0,0,4-4V48a4,4,0,0,0-4-4H48a4,4,0,0,0-4,4v96a4,4,0,0,1-8,0V48A12,12,0,0,1,48,36H208A12,12,0,0,1,220,48ZM117.17,157.17,64,210.34,42.83,189.17a4,4,0,0,0-5.66,5.66l24,24a4,4,0,0,0,5.66,0l56-56a4,4,0,0,0-5.66-5.66Z"></path></svg>
                    </th>
                    <th
                      class="px-6 py-3 "
                    >
                      URL do site
                    </th>
                    <th
                      class="px-6 py-3 "
                    >
                      Status
                    </th>
                    <th
                      class="px-6 py-3 "
                    >
                      Data de implementação
                    </th>
                    <th
                      class="px-6 py-3"
                    >
                      Opções
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="item in list" :key="item._id" class="text-gray-500 text-sm text-center">

                    <td class="px-3 py-4 whitespace-nowrap text-left" v-if="!arquivadas">
                      <input :checked="selecionados.map(el => el._id).indexOf(item._id) > -1" @change="(e) => selecionadosControl(item, e)" type="checkbox" class="rounded-sm">
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap text-left">
                        {{ item.url }}
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap">
                        {{ item.dataImplementacao ? 'Implementado': 'Não implementado' }}
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap">
                        {{ item.dataImplementacao | moment("DD/MM/YYYY") }}
                    </td>
                    
                    <td class="px-6 py-4 text-white flex flex-wrap justify-center gap-2 ">
                      <button
                        @click="pegarCodigo(item._id)"
                        type="button"
                        class="hover:bg-green-700 bg-green-600 rounded py-1 px-4"
                      >
                        Plugin
                      </button>
                     
                      <router-link
                        :to="`/requisicao/config/${item._id}`"
                        role="button"
                        type="button"
                        class="hover:bg-gray-700 bg-gray-600 rounded py-1 px-4"
                      >
                        Personalizar
                      </router-link>
                      <button
                        v-if="!arquivadas"
                        @click="edit(item._id)"
                        type="button"
                        class=" hover:bg-blue-500 bg-blue-700 rounded py-1 px-4"
                      >
                        Editar URL
                      </button>

                      <!-- <button
                        v-if="!arquivadas"
                        @click="remove(item._id)"
                        type="button"
                        class=" hover:bg-red-500 bg-red-700 rounded py-1 px-4"
                      >
                        Arquivar
                      </button> -->
                      <button
                        v-if="arquivadas"
                        @click="ativar(item._id)"
                        type="button"
                        class="hover:bg-green-800 bg-green-700 rounded py-1 px-4"
                      >
                        Ativar
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <pagination
              v-model="page"
              :per-page="perPage"
              :records="total"
              @paginate="setPage"
              class="pagination"
            />
          </div>
        </div>
      </div>


      <modal name="novaURL" :width="800" :adaptive="true" :height="'auto'" :clickToClose="false">
        <div class="px-3 py-3">
          <h2 class="text-2xl font-bold mb-3">{{ form._id ? 'Editar':'Nova' }} configuração de requisição</h2>
          <div class="flex flex-col gap-1 ">
            <label for="url" class="block text-sm font-medium">URL do site</label>
            <input id="url" v-model="form.url" type="text" class="focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-lg"/>
          </div>

          <div class="grid grid-cols-2 mt-5">
            <div class="text-left">
                <button @click="$modal.hide('novaURL')" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                    Voltar
                </button>
            </div>
            <div class="text-right">
                <button @click="save" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                    Salvar
                </button>
            </div>
          </div>
        </div>
      </modal>

      <modal name="pluginSite" :adaptive="true" :height="'auto'">
        <div class="px-5 py-8">
          <h2 class="text-2xl font-bold mb-5">Plugin para o site</h2>
          <div class="grid grid-cols-12 gap-4">

            <div class="col-span-12">
              <label for="codigo" class="block text-sm font-medium">Copie o código abaixo e cole <b class="text-blue-800">no final da body html</b> do seu site para gerenciamento de solicitações de titulares e canal de denúncia de incidentes.</label>
              <textarea v-model="codigo" type="text" name="codigo" id="codigo" rows="4" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-lg"></textarea>
            </div>
            
          </div>

          <div class="grid grid-cols-2 mt-5">
            <div class="text-left">
                <button @click="$modal.hide('pluginSite')" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                    Voltar
                </button>
            </div>
          </div>
        </div>
      </modal>
      <modal name="pluginSite" :adaptive="true" :height="'auto'">
        <div class="px-5 py-8">
          <h2 class="text-2xl font-bold mb-5">Plugin para o site</h2>
          <div class="grid grid-cols-12 gap-4">

            <div class="col-span-12">
              <label for="codigo" class="block text-sm font-medium">Copie o código abaixo e cole <b class="text-blue-800">no final da body html</b> do seu site para gerenciamento de solicitações de titulares e canal de denúncia de incidentes.</label>
              <textarea disabled v-model="codigo" type="text" name="codigo" id="codigo" rows="6" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-lg"></textarea>
            </div>
            
          </div>

          <div class="grid grid-cols-2 mt-5">
            <div class="text-left">
                <button @click="$modal.hide('pluginSite')" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                    Voltar
                </button>
            </div>
          </div>
        </div>
      </modal>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  data() {
    return {
      route: "requisicao/gerenciar",
      form: {
        url: '',
        empresa: null,
        data: null,
      },
      titulares: [],
      list: [],
      selecionados: [],
      page: 1,
      perPage: 10,
      busca: "",
      arquivadas: false,
      codigo: "",
      total: 0,
    };
  },
  methods: {
    async start() {
      const filtro = {
        busca: this.busca,
        limit: this.perPage,
        arquivadas: this.arquivadas,
        t: moment().format('x'),
      }
      const listReq = await this.$http.post(`/v1/${this.route}/list`, filtro);
      this.list = listReq.data.itens;
      this.total = listReq.data.total;
    },

    async pegarCodigo(id){

      const item = this.list.find((item) => item._id === id);

      await this.$http.post(`/v1/requisicao/configRequisicao`, item)
      .then((resp)=> {
        this.codigo = resp.data.codigo;
        this.$modal.show('pluginSite');
      })
      .catch((e)=>{
        console.log('Erro ao pegar código: ', e)
        this.$vToastify.error('Erro ao pegar código.');
      })
    },

    async ativar(id) {
      await this.$http.put(`/v1/${this.route}/`, { _id: id, ativo: true });
      this.$vToastify.success("Ativado com sucesso!");
      this.start();
    },

    async edit(id) {
      const paraEditar = this.list.find((item) => item._id === id);
      Object.assign(this.form, paraEditar)
      this.$modal.show('novaURL')
    },
    async novo(){
      this.form.url = '';
      this.form._id = null;
      this.form.empresa = null;
      this.$modal.show('novaURL')
    },

    async remove(id) {
      await this.$http.delete(`/v1/${this.route}/${id}`);
      this.$vToastify.success("Arquivado com sucesso!");
      this.start();
    },

    async selecionarTodos(){
      if (!this.selecionados?.length) {
        const listReq = await this.$http.post(`/v1/${this.route}/list`, { 
          all: true, 
        });
        this.selecionados = listReq.data.itens;
      }else {
        this.selecionados = [];
      }
    },

    async excluirSelecionadas(){

      if(this.selecionados?.length === 0){
        return this.$vToastify.warning("Nenhuma requisição selecionada.");
      }

      this.$confirm({
        title: 'Arquivar selecionados',
        message: `Deseja arquivar itens selecionados ?`,
        button: {
            no: 'Não',
            yes: 'Sim',
        },
        callback: async confirm => {
          if(!confirm) return;
          await this.$http.post(`/v1/${this.route}/selecionados/deletar`,{ selecionados: this.selecionados });
          this.selecionados = [];
          this.$vToastify.success("Removido com sucesso!");
          this.start();
        }
      })
    },

    selecionadosControl(item, e){
      if (this.selecionados.map(el => el._id).indexOf(item._id) > -1 && !e.target.checked) {
        this.selecionados.splice(this.selecionados.map(el => el._id).indexOf(item._id), 1);
      } else {
        this.selecionados.push(item);
      }
    },

    async save() {
      const metodo = this.form._id ? 'put': 'post'

      if(!this.form.url) return this.$vToastify.warning('Informe URL');

      await this.$http[metodo](`/v1/${this.route}`, this.form)
      .then((resp)=>{
        this.$vToastify.success(resp.data.message);
        this.$modal.hide('novaURL')
        this.start();
      })
      .catch((e)=>{
        console.log('Erro ao editar: ', e)
        this.$vToastify.error('Erro ao editar.');
      })
    },

    async setPage(page) {
      this.page = page;
      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;

      const listReq = await this.$http.post(`/v1/${this.route}/list`, { limit, skip });
      this.list = listReq.data.data;
      this.total = listReq.data.total;
    },

  },
  async beforeMount() {
    this.start();
  },
};
</script>
